class GloITInfoGloCard extends BATComponent {
	/* Lifecycle event callbacks */
	beforeLoad() {
		const regex = /^.*\.[^\\]+$/gm;
		if (!regex.test(this.data.image.imageSrc.desktop.src)) {
			this.data.canvas = {...this.data.image};
			delete this.data.image;
		} else if (this.data.image.imageSrc.desktop.src.endsWith('.webm') 
			|| this.data.image.imageSrc.desktop.src.endsWith('.mp3')) {
				this.data.customVideo = true;
			}

		if (this.data.text) {
			let newText = '';
			$(this.data.text).each((index, elem) => {
				const selected = $(elem);
				if (selected.prop("tagName") === 'P') {
					selected.addClass('infoglocard-text');
				}
				if (selected.prop("tagName") === 'UL') {
					selected.addClass('infoglocard-infolist');
				}
				if (selected.prop('outerHTML')) {
					newText += selected.prop('outerHTML');
				} else {
					newText += selected.text();
				}
			});
			this.data.text = newText;
		}
	}

	afterLoad() {
		// console.log('MastheadCard After load');
		this.$el = $(this);
		addScrollMagicScript(this.data).then(() => {
			this.addScrollListener()
		});
		this.moveCarousel();
	}


	beforeUpdate() {
		// console.log('MastheadCard before update');
	}

	afterUpdate() {
		// console.log('MastheadCard after update');
	}

	unload() {
		// console.log('MastheadCard after unload');
	}

	moveCarousel(){
		const isAuthorMode = this.$el.hasClass('author-mode');
		const carousel = $("bat-carousel-gloitinfoglo");
		if(carousel.length > 0 && !isAuthorMode){
			const button = this.$el.find(".bat-infoglocard-gloit .infoglocard-cta");
			carousel.insertBefore(button);
		} else if (carousel.length === 0) {
			setTimeout(this.moveCarousel, 1000);
		}
	}

	addScrollListener() {
		const videoEl = this.$el.find(".video-card");
	
		if(videoEl.length > 0) {
			const canvas = videoEl[0];
			const context = canvas.getContext("2d");

			const render = () => {
				context.clearRect(0, 0, canvas.width, canvas.height);
				if (window.innerWidth < 800) {
					context.drawImage(images[airpods.frame], -75, 0, canvas.width + 150, canvas.height);
				} else {
					context.drawImage(images[airpods.frame], -150, 0, canvas.width + 300, canvas.height); 
				}
			}

			const frameCount = 134;
			const currentFrame = index => (
				`${this.data.canvas.imageSrc.desktop.src}/img${(index + 1).toString().padStart(3, '0')}.webp`
			);

			const images = []
			const airpods = {
				frame: 0
			};

			for (let i = 0; i < frameCount; i++) {
				const img = new Image();
				img.src = currentFrame(i);
				images.push(img);
			}

			canvas.style.width = '100%';
			canvas.width = canvas.offsetWidth;
			const heightRatio = 0.83;
			canvas.height = canvas.width * heightRatio;
			canvas.style.height = canvas.height + 'px';
			canvas.height = canvas.offsetHeight;
	
			gsap.to(airpods, {
				frame: frameCount - 1,
				snap: "frame",
				ease: "none",
				scrollTrigger: {
					trigger: canvas.parentElement,
					scrub: true,
					start: "top bottom",
					end: "top 20%"
				},
				onUpdate: render // use animation onUpdate instead of scrollTrigger's onUpdate
			});
			  
			images[0].onload = render;

			$(window).on('resize', () => {
				canvas.width = canvas.offsetWidth;
				canvas.height = canvas.width * heightRatio;
				canvas.style.height = canvas.height + 'px';
				canvas.height = canvas.offsetHeight;
				render();
			});

			/*const controller = new ScrollMagic.Controller();
			const scene = new ScrollMagic.Scene({
				triggerElement: video,
				triggerHook: 0,
			})
			scene.addTo(controller);
	
			const header = document.querySelector(".bat-header-wrapper")
			const headerHeight = header.offsetHeight;
			const innerHeight = window.innerHeight; 
			const halfHeight = video.getBoundingClientRect().height / 2;

			scene.on('update', function (e) {
				
				//normalize between 0 and 1 = (value - min) / (max - min)
	
				let min;
				let max;
				let value;
	
				if (videoOffset.top < innerHeight) {
					min = 0;
					max = videoOffset.bottom - headerHeight - halfHeight;
					value = e.scrollPos;
	
				} else {
					min = videoOffset.top + halfHeight;
					max = videoOffset.bottom + innerHeight - headerHeight - halfHeight;
					value = e.scrollPos + innerHeight;
				}
	
				const normalized = (value - min) / (max - min);
	
				//we multiply by the video duration to get the current time
				window.currentTime = normalized * video.duration;
				
				if (!window.currentTime || window.currentTime < 0 || window.currentTime > video.duration) {
					window.currentTime = 0;
				}
	
				window.requestAnimationFrame(() => {
					video.currentTime = window.currentTime;
				});
			});

			document.on('scroll touchmove', () => {
				scroll.trigger('update');
			})*/
		}
	
	}
}

function addScrollMagicScript(data) {
	return new Promise(async (resolve) => {
		if (data.canvas) {
	
			const scrollMagic = document.createElement('script');
			scrollMagic.src = 'https://cdnjs.cloudflare.com/ajax/libs/gsap/3.11.1/gsap.min.js';
			scrollMagic.onload = () => {
				const scrollMagicIndicators = document.createElement('script');
				scrollMagicIndicators.src = 'https://cdnjs.cloudflare.com/ajax/libs/gsap/3.11.1/ScrollTrigger.min.js';
				
				scrollMagicIndicators.onload = () => {
					window.currentTime = 0;
					window.accelAmount = 0.1;
					window.delay = 0;
					resolve();
				}

				document.body.appendChild(scrollMagicIndicators);
			};
			document.body.appendChild(scrollMagic);
		} else {
			resolve();
		}

	});

}

function offset(el) {
    var rect = el.getBoundingClientRect(),
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
	scrollBottom = scrollTop + el.height;
    return { top: rect.top + scrollTop, bottom: rect.bottom + scrollBottom }
}

!customElements.get('bat-card-gloitinfoglo') &&
	customElements.define('bat-card-gloitinfoglo', GloITInfoGloCard);
